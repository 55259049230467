<template>
  <SearchBox
    v-model="fulltext"
    :label="label"
    name="search"
    :placeholder="$t('common:text-search-placeholder')"
    :on-submit="onSearch"
    :on-clear-search="clearSearch"
    :on-filter="onFilter"
    :on-speech="onSpeech"
  />
</template>

<script setup>
import _ from "lodash-es";
import { ROUTES } from "@utils/routes";
import { useSelectedStore } from '@stores/selected'
import SearchBox from "@components/ui/search-box";

const windowSize = useWindowSize();

const route = useRoute();
const fulltext = ref(route?.query?.fulltext || '');

defineProps({
  label: {
    type: String,
    required: true,
    default: () => ''
  }
});

const { $eventBus } = useNuxtApp();
const store = useSelectedStore();

watch(() => route.query.fulltext, (value) => fulltext.value = value);

const onSearch = async () => {
  if (route.path === translatePath(ROUTES.CODE.HOME)) {
    await navigatorTo({
      path: translatePath(ROUTES.CODE.ADS),
      query: { fulltext: fulltext.value }
    });
  } else {
    await useSearchParam('fulltext', fulltext.value);
  }
};

const clearSearch = async () => {
  fulltext.value = '';
  await useSearchParam('fulltext', fulltext.value);
}

const onFilter = async () => {
  if (route.path === translatePath(ROUTES.CODE.HOME)) {
    await navigatorTo({
      path: translatePath(ROUTES.CODE.ADS),
      query: fulltext.value ? { fulltext: fulltext.value } : {}
    });
    if (windowSize?.width?.value < 1024) {
      store.setAction('OPEN_FILTER_VIEW');
    } else {
      setTimeout(() => {
        $eventBus.emit('focus:filters');
      }, 500);
    }
  } else if (windowSize?.width?.value < 1024) {
    $eventBus.emit('sidebar:open', 'FILTER_VIEW');
  } else if (import.meta.client) {
    nextTick(() => {
      if (document.getElementById('cardsView')?.offsetTop) {
        window.scrollTo({ top: (document.getElementById('cardsView')?.offsetTop || 0) - 120, behavior: "smooth" });
      } else if (document.getElementById('productsView')?.offsetTop) {
        window.scrollTo({ top: (document.getElementById('productsView')?.offsetTop || 0) - 120, behavior: "smooth" });
      } else if (document.getElementById('contentView')?.offsetTop) {
        window.scrollTo({ top: (document.getElementById('contentView')?.offsetTop || 0) - 120, behavior: "smooth" });
      } else if (document.getElementById('newsView')?.offsetTop) {
        window.scrollTo({ top: (document.getElementById('newsView')?.offsetTop || 0) - 120, behavior: "smooth" });
      }
    });
  }
};

const onSpeech = () => {
  // TODO
  console.log('TODO.search.onSpeech');
};

</script>
