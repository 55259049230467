<template>
  <div class="w-full lg:max-w-440">
    <div :class="[ 'rounded-full flex relative group', sizeClass ]">
      <label htmlFor="label" class="sr-only">
        {{ label }}
      </label>

      <input
        :id="label"
        ref="searchInputEl"
        type="text"
        :value="modelValue"
        autoComplete="off"
        :class="[ 'w-full h-full flex item-center appearance-none transition duration-300 ease-in-out text-sm overflow-hidden rounded-full focus:outline-none focus:ring-0', classes[variant] ]"
        v-bind="$attrs"
        @input="$emit('update:modelValue', $event.target.value)"
        @change="handleSearchChange"
        @keyup.enter="onSubmit"
      >
      <button
        v-if="modelValue"
        type="button"
        :aria-label="$t('text-close')"
        :class="[ 'cursor-pointer h-full w-8 flex items-center justify-center absolute transition-colors duration-200 focus:outline-none', variant === 'normal' ? 'end-36' : '', variant === 'big' ? 'end-12 text-accent-dark group-hover:text-light focus:text-light' : 'text-body hover:text-accent-hover focus:text-accent-hover' ]"
        @click="handleClearSearch"
      >
        <span class="sr-only">{{ $t('text-close') }}</span>
        <CloseIcon class="w-3.5 h-3.5 md:w-3 md:h-3" />
      </button>
      <button
        v-if="variant === 'minimal'"
        class="h-full w-10 md:w-14 flex items-center justify-center absolute start-0 text-body transition-colors duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-hover"
        :aria-label="$t('common:text-search')"
        @click="onSubmit"
      >
        <span class="sr-only">{{ $t("common:text-search") }}</span>
        <SearchIcon class="w-3.5 h-3.5 md:w-4 md:h-4" />
      </button>
      <button
        v-else-if="variant === 'normal'"
        class="h-full px-8 flex items-center rounded-full rounded-l-none bg-accent text-light font-semibold transition-colors duration-200 focus:outline-none hover:bg-accent-hover focus:bg-accent-hover"
        :aria-label="$t('common:text-search')"
        @click="onSubmit"
      >
        <SearchIcon class="w-4 h-4 me-2.5" />
        {{ $t('common:text-search') }}
      </button>
      <button
        v-else-if="variant === 'big'"
        class="h-full w-14 flex items-center justify-center absolute start-0 text-light transition-colors duration-200 focus:outline-none group-hover:text-light focus:text-light"
        :aria-label="$t('common:text-search')"
        @click="onSubmit"
      >
        <span class="sr-only">{{ $t("common:text-search") }}</span>
        <SearchIcon class="w-5 h-5" />
      </button>
      <div
        v-if="variant === 'big'"
        class="h-full w-10 flex items-center justify-end absolute end-5 text-primary-neutral transition-colors duration-200 focus:outline-none group-hover:text-light focus:text-light"
      >
        <span class="sr-only">{{ $t("common:text-search") }}</span>
        <button
          class="flex items-center justify-center pr-2 transition-colors duration-200 focus:outline-none h-9"
          :aria-label="$t('text-filter')"
          @click="onFilter"
        >
          <FilterIcon class="w-5 h-5" />
        </button>
        <button
          v-if="false"
          class="flex items-center justify-center pl-2 transition-colors duration-200 focus:outline-none border-l border-primary-neutral h-9"
          :aria-label="$t('text-voice-search')"
          @click="onSpeech"
        >
          <MicrophoneIcon class="w-5 h-5" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import SearchIcon from '@components/icons/search-icon'
import CloseIcon from "@components/icons/close-icon";
import FilterIcon from "@components/icons/filter-icon";
import MicrophoneIcon from "@components/icons/microphone-icon";

defineOptions({
  inheritAttrs: false
})

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  onSubmit: {
    type: Function,
    default: () => { }
  },
  onClearSearch: {
    type: Function,
    default: () => { }
  },
  onFilter: {
    type: Function,
    default: () => { }
  },
  onSpeech: {
    type: Function,
    default: () => { }
  },
  variant: {
    type: String,
    default: 'normal'
  }
});

const classes = {
  minimal: "bg-gray-100 ps-10 pe-12 md:ps-14 border border-border-200 focus:border-accent focus:bg-light text-bolder placeholder-gray-500",
  normal: "bg-light ps-6 pe-14 rounded-r-none border border-e-0 border-transparent focus:border-accent text-bolder placeholder-gray-500",
  big: "ps-12.5 pe-20 border border-primary-neutral hover:border-light focus:border-light text-light placeholder-light focus:placeholder-transparent bg-transparent",
};

const searchInputEl = ref(null);

const sizeClass = computed(() => {
  return {
    minimal: 'h-11 md:h-12',
    normal: 'h-14 shadow-900',
    big: 'h-12 lg:h-15',
  }[props.variant];
});

const handleSearchChange = (event) => {
  emit('update:modelValue', event.target.value);
  props.onSubmit();
}

const handleClearSearch = () => {
  props.onClearSearch();
  searchInputEl.value.focus();
}

</script>
